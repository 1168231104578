<script>
import Breadcrumbs from '@/components/general/Breadcrumbs'
import PositionDetails from '@/components/positions/PositionDetails'

export default {
  name: 'PositionView',
  components: {
    Breadcrumbs,
    PositionDetails
  },
  data () {
    return {
      id: this.$route.params.positionId,
      paths: [],
      positionRelatedCourses: [],
      title: '...'
    }
  },
  methods: {
    handleTitle (position) {
      this.title = position.title
      this.paths[1].text = `${this.$t('global:position')} ${position.title}`
    }
  },
  created () {
    this.paths.push({ text: 'main.header:discovery', href: `${this.getWorkspaceRoot()}/discovery` }, { text: `${this.$t('global:position')} ...`, href: this.$route.path })
  }
}
</script>

<template>
  <section class="position-view--container center-small">
    <div class="default-header">
      <breadcrumbs :items="paths"/>
      <h2>{{ ($t('global:position') + ' ' + title) }}</h2>
    </div>

    <v-card class="position-view--content">
      <position-details :id="id" :onReady="handleTitle" />
    </v-card>
  </section>
</template>
<style lang="scss">
.position-view--container {
  .default-header{
    margin-bottom: 44px;
  }
  .position-view--content {
    margin-bottom: 80px;
  }
  .content-position-details-container {
    margin-top: 0;
    padding-top: 0;
    .content-position-details--header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 16px;
      background-color: #fafafa;
      h2 {
        line-height: 1em;
      }
    }
    .content-position-details--body {
      padding: 100px 40px 40px 40px;
    }
  }
}
</style>
